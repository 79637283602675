import * as React from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { Button, Col, Form, Row } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const Faucet = () => {
  const address = useGetAccountInfo().address;
  const [faddress, setAddress] = React.useState(
    address === null ? '' : address
  );
  const [amount, setAmount] = React.useState(1);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  React.useEffect(() => {
    setAddress(address === null ? '' : address);
  }, [address]);

  const [state, setState] = React.useState({
    urls: [
      { network: 'T', explorer: 'https://testnet-explorer.multiversx.com/' },
      { network: 'D', explorer: 'https://devnet-explorer.multiversx.com/' },
      { network: 'D2', explorer: 'https://devnet2-explorer.multiversx.com/' }
    ],
    tokens: [
      { id: '1', identifier: 'EGLD', network: 'T', balance: 0, decimals: 18 },
      { id: '2', identifier: 'EGLD', network: 'D', balance: 0, decimals: 18 },
      { id: '3', identifier: 'EGLD', network: 'D2', balance: 0, decimals: 18 }
    ],
    transactions: [
      {
        amount: 0,
        address: '',
        decimals: 18,
        identifier: 'EGLD',
        network: 'T',
        tx_result: ''
      }
    ],
    network: 'T',
    token_id: '1'
  });
  //Tableau des tokens du faucet
  const [tokens, setTokens] = React.useState({
    tokens: [
      {
        id: '1',
        identifier: 'EGLD',
        network: 'T',
        balance: 0,
        decimals: 18,
        max: 1
      },
      {
        id: '2',
        identifier: 'EGLD',
        network: 'D',
        balance: 0,
        decimals: 18,
        max: 1
      },
      {
        id: '3',
        identifier: 'EGLD',
        network: 'D2',
        balance: 0,
        decimals: 18,
        max: 1
      }
    ]
  });
  //Tableau des transactions du faucet
  const [transactions, setTransactions] = React.useState({
    transactions: [
      {
        amount: 0,
        address: '',
        decimals: 18,
        identifier: 'EGLD',
        network: 'T',
        tx_result: '',
        age: 0,
        status: '?'
      }
    ]
  });
  //Network selectionné (default)
  const [cnetwork, setNetwork] = React.useState({
    network: 'D'
  });
  //Token selectionné (default)
  const [ctoken, setToken] = React.useState({
    token_id: '2',
    token_index: 0
  });

  function secondsToHms(d: number) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  }

  function setNetworkID(e: React.ChangeEvent<any>) {
    setNetwork({
      network: e.target.value
    });
    let tmp = '0';
    if (e.target.value === 'T') {
      tmp = '1';
    } else if (e.target.value === 'D') {
      tmp = '2';
    } else if (e.target.value === 'D2') {
      tmp = '3';
    }
    const index = tokens.tokens
      .filter(({ network }) => network === e.target.value)
      .findIndex((tkp) => tkp.id === tmp);
    setToken({
      token_id: tmp,
      token_index: index
    });
    setAmount(
      tokens.tokens.filter(({ network }) => network === e.target.value)[index]
        .max
    );
    setError('');
    setSuccess('');
  }

  function setTokenID(e: React.ChangeEvent<any>) {
    const index = tokens.tokens
      .filter(({ network }) => network === cnetwork.network)
      .findIndex((tokens) => tokens.id === e.target.value);
    setToken({
      token_id: e.target.value,
      token_index: index
    });
    setAmount(
      tokens.tokens.filter(({ network }) => network === cnetwork.network)[index]
        .max
    );
    setError('');
    setSuccess('');
  }

  //Liste des tokens via API
  React.useEffect(() => {
    const fetchTokenList = async () => {
      const { data } = await axios('https://api.r3d4.fr/faucet/tokens');
      console.log(data);
      setTokens({
        tokens: data
      });
    };
    fetchTokenList();
    //refresh 60 sec
    const interval = setInterval(() => {
      fetchTokenList();
    }, 60000);
    return () => clearInterval(interval);
  }, [setState]);

  //Liste des TX via API
  React.useEffect(() => {
    const fetchTransactionList = async () => {
      //Si connecté, permet d'avoir plus d'historique...
      let fetchurl = 'https://api.r3d4.fr/faucet/list';
      if (faddress != '') {
        fetchurl = 'https://api.r3d4.fr/faucet/list/A/address/' + faddress;
      }
      const { data } = await axios(fetchurl);

      setTransactions({
        transactions: data
      });
    };
    fetchTransactionList();
    //refresh 10 sec
    const interval = setInterval(() => {
      fetchTransactionList();
    }, 10000);
    return () => clearInterval(interval);
  }, [setState]);

  function handleAddressChange(e: React.ChangeEvent<any>) {
    setAddress(e.target.value);
    setError('');
    setSuccess('');
  }

  function handleAmountChange(e: React.ChangeEvent<any>) {
    setAmount(e.target.value);
    setError('');
    setSuccess('');
  }

  function handleSubmit() {
    const formdata = {
      network: cnetwork.network,
      token: ctoken.token_id,
      address: faddress,
      amount: amount
    };
    axios.post('https://api.r3d4.fr/faucet/list', { formdata }).then((res) => {
      setError(res.data.error);
      setSuccess(res.data.success);
    });
  }

  function Copytext() {
    copy('erd1xkmduha0sn4k3yx9fsapavlxrnh2e4fxm8qlafgdgmy6pg4y4zkqav9tdk');
  }

  return (
    <div className='container py-4'>
      <p>
        This faucet deliver{' '}
        {cnetwork.network === 'T' && 'EGLD and ESDT for the mutliversx Testnet'}
        {cnetwork.network === 'D' &&
          'EGLD and ESDT for the mutliversx Devnet'}{' '}
        {cnetwork.network === 'D2' &&
          'EGLD and ESDT for the mutliversx Devnet '}{' '}
        network.
        <br />
        <br /> Fill the form with your wallet address to receive EGLD or ESDT.
        <br />
        <br /> Only one claim per token type for a 24 hour period.
      </p>
      <p>
        To list new ESDT on faucet, send them to the corresponding network at
        this address :
        <br /> erd1xkmduha0sn4k3yx9fsapavlxrnh2e4fxm8qlafgdgmy6pg4y4zkqav9tdk{' '}
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={Copytext}
        >
          <i className='fa fa-copy'></i>
        </button>
      </p>
      <Form>
        <Row className='mb-3'>
          <Form.Group as={Col} md='6' controlId='network'>
            <Form.Label>Network</Form.Label>
            <Form.Control as='select' onChange={setNetworkID}>
              <option value='D'>Devnet (NEW)</option>
              <option value='T'>Testnet</option>
              {/* <option value='D2'>Devnet (new)</option> */}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} md='6' controlId='asset'>
            <Form.Label>Asset</Form.Label>
            <Form.Control
              as='select'
              onChange={setTokenID}
              value={ctoken.token_id}
            >
              {tokens.tokens &&
                tokens.tokens
                  .filter(({ network }) => network === cnetwork.network)
                  .map((item, index) => (
                    <option
                      key={index}
                      value={item.id}
                      disabled={item.balance / Math.pow(10, item.decimals) < 1}
                    >
                      {item.identifier} (
                      {(item.balance / Math.pow(10, item.decimals)).toFixed(0)})
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className='mb-3'>
          <Form.Group
            as={Col}
            md='6'
            controlId='walletAddress'
            onChange={handleAddressChange}
          >
            <Form.Label>Wallet Address</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='erd1'
              value={faddress}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            md='6'
            controlId='faucetAmount'
            onChange={handleAmountChange}
          >
            <Form.Label>
              {tokens.tokens.filter(
                ({ network }) => network === cnetwork.network
              )[ctoken.token_index].identifier +
                ' amount (Max ' +
                tokens.tokens.filter(
                  ({ network }) => network === cnetwork.network
                )[ctoken.token_index].max +
                ' of ' +
                (
                  tokens.tokens.filter(
                    ({ network }) => network === cnetwork.network
                  )[ctoken.token_index].balance /
                  Math.pow(
                    10,
                    tokens.tokens.filter(
                      ({ network }) => network === cnetwork.network
                    )[ctoken.token_index].decimals
                  )
                ).toFixed(2) +
                ') (' +
                tokens.tokens.filter(
                  ({ network }) => network === cnetwork.network
                )[ctoken.token_index].decimals +
                ' decimals)'}
            </Form.Label>
            <Form.Control
              required
              type='number'
              placeholder=''
              defaultValue='1'
              value={amount}
            />
          </Form.Group>
        </Row>

        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Button variant='primary' onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Group>
        </Row>
      </Form>

      {error && (
        <div className='alert alert-warning' role='alert'>
          {error}
        </div>
      )}
      {success && (
        <div className='alert alert-success' role='alert'>
          {success}
        </div>
      )}

      <p>
        Awaiting and closed transactions{' '}
        <i className='fa fa-spinner fa-spin'></i> :
      </p>
      <table className='table table-striped'>
        <tr>
          <th>Address</th>
          <th>Token</th>
          <th>Amount</th>
          <th>Hash</th>
          <th>Age</th>
          <th>Status</th>
        </tr>
        {transactions.transactions &&
          transactions.transactions
            .filter(({ network }) => network === cnetwork.network)
            .filter(
              ({ address }) =>
                address === faddress || faddress.length != 62 || faddress == ''
            )
            .map((item, index) => (
              <tr key={index}>
                <td>
                  {item.address.substring(0, 9) +
                    '....' +
                    item.address.substring(57, 62)}
                </td>
                <td>{item.identifier}</td>
                <td>{item.amount / Math.pow(10, item.decimals)}</td>

                <td>
                  <a
                    href={
                      state.urls.filter(
                        ({ network }) => network === cnetwork.network
                      )[0].explorer +
                      'transactions/' +
                      item.tx_result
                    }
                    target='_BLANK'
                    rel='noreferrer'
                  >
                    {item.tx_result.substring(0, 5)}
                  </a>
                </td>
                <td>{secondsToHms(item.age)}</td>
                <td>{item.status}</td>
              </tr>
            ))}
      </table>
    </div>
  );
};

export default Faucet;
