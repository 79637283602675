import * as React from 'react';
import { useState } from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { refreshAccount, sendTransactions } from 'helpers';
import { useGetPendingTransactions } from 'hooks';
import { Button } from 'react-bootstrap';

export const Actions = (datas: any) => {
  console.log(datas.datas);
  const { hasPendingTransactions } = useGetPendingTransactions();
  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);

  const sendEsdtTransaction = async () => {
    const pingTransaction = {
      value: '50000000000000000',
      data: datas.datas,
      receiver:
        'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u',
      gasLimit: '60000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: pingTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Issue transaction',
        errorMessage: 'An error has occured during Issue',
        successMessage: 'Issue transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return (
    <div className=''>
      {!hasPendingTransactions && (
        <div
          onClick={sendEsdtTransaction}
          data-testid='btnPing'
          data-cy='transactionBtn'
        >
          <Button variant='primary'>
            submit tx on devnet2 network{' '}
            <FontAwesomeIcon icon={faArrowUp} className='text-primary' />
          </Button>
        </div>
      )}
    </div>
  );
};
