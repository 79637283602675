import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className='text-center mt-2 mb-3'>
      <div>
        <a
          {...{
            target: '_blank'
          }}
          className='d-flex align-items-center'
          href='https://elrond.com/'
        >
          <p>
            Build on <span className='font-weight-bold'>MultiversX</span>
          </p>
        </a>
        <Link className='' to={'/team'}>
          <p>
            By <span className='font-weight-bold'>team</span> with{' '}
            <HeartIcon className='mx-1' />
          </p>
        </Link>
      </div>
    </footer>
  );
};
