import * as React from 'react';
import copy from 'copy-to-clipboard';
import { Button, Col, Form, Row } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { Actions } from './components';
const Esdt = () => {
  const [formControl, setFormControl] = React.useState('');
  const [ready, setReady] = React.useState(false);
  const [datas, setDatas] = React.useState('issue@');
  const [tokenName, setTokenName] = React.useState('');
  const [tokenTicker, setTokenTicker] = React.useState('');
  const [tokenAmount, setTokenAmount] = React.useState(0);
  const [tokenDecimals, setTokenDecimals] = React.useState(0);
  const [bigAmount, setBigAmount] = React.useState(BigInt(0));

  const [canUpgrade, setCanUpgrade] = React.useState(true);
  const [canFreeze, setCanFreeze] = React.useState(false);
  const [canWipe, setCanWipe] = React.useState(false);
  const [canPause, setCanPause] = React.useState(false);
  const [canMint, setCanMint] = React.useState(false);
  const [canBurn, setCanBurn] = React.useState(false);
  const [canChangeOwner, setCanChangeOwner] = React.useState(false);
  const [canAddSpecialRoles, setCanAddSpecialRoles] = React.useState(false);
  const address = useGetAccountInfo().address;

  /*
    Token Name : 3-20 [a-zA-Z0-9]
    Token ticker :    3-10 [A-Z0-9]
    Amount to issue : [0-9]
    Decimals : 18[0-9]
    */

  function handleTokenNameChange(e: React.ChangeEvent<any>) {
    setTokenName(e.target.value);
    setReady(false);
  }
  function handleTokenTickerChange(e: React.ChangeEvent<any>) {
    setTokenTicker(e.target.value.toUpperCase());
    setReady(false);
  }

  function handleTokenDecimalsChange(e: React.ChangeEvent<any>) {
    let dec = e.target.value;
    if (dec <= 0) {
      dec = 0;
    } else if (dec > 18) {
      dec = 18;
    }

    setTokenDecimals(dec);

    const output = toBigAmount(tokenAmount, dec);
    setBigAmount(BigInt(output));
    setReady(false);
  }

  function handleTokenAmountChange(e: React.ChangeEvent<any>) {
    const amount = e.target.value;

    if (amount < 0) {
      setTokenAmount(0);
      setBigAmount(BigInt(0));
    } else {
      setTokenAmount(amount);
      const output = toBigAmount(amount, tokenDecimals);
      setBigAmount(BigInt(output));
    }
    setReady(false);
  }
  function handleCanUpgrade() {
    setCanUpgrade(!canUpgrade);
    setReady(false);
  }
  function handleCanFreeze() {
    setCanFreeze(!canFreeze);
    setReady(false);
  }
  function handleCanWipe() {
    setCanWipe(!canWipe);
    setReady(false);
  }
  function handleCanPause() {
    setCanPause(!canPause);
    setReady(false);
  }
  function handleCanMint() {
    setCanMint(!canMint);
    setReady(false);
  }
  function handleCanBurn() {
    setCanBurn(!canBurn);
    setReady(false);
  }
  function handleCanChangeOwner() {
    setCanChangeOwner(!canChangeOwner);
    setReady(false);
  }
  function handleCanAddSpecialRoles() {
    setCanAddSpecialRoles(!canAddSpecialRoles);
    setReady(false);
  }

  function handleSubmit() {
    if (tokenName.length < 3 || tokenName.length > 20) {
      setFormControl('Token Name invalid length');
    } else if (!/^[a-zA-Z0-9]+$/g.test(tokenName)) {
      setFormControl('Token Name invalid value [a-zA-Z0-9]');
    } else if (tokenTicker.length < 3 || tokenTicker.length > 10) {
      setFormControl('Token Ticker invalid length');
    } else if (!/^[A-Z0-9]+$/g.test(tokenTicker)) {
      setFormControl('Token Ticker invalid value [A-Z0-9]');
    } else {
      setFormControl('');
      const str =
        'issue' +
        '@' +
        toHex(tokenName) +
        '@' +
        toHex(tokenTicker) +
        '@' +
        bigToHexDec(bigAmount) +
        '@' +
        toHexDec(tokenDecimals);
      setDatas(str);
      setReady(true);
    }
  }

  //Converti un nombre décimal en bigInt 10^18
  function toBigAmount(invalue: number, indec: number) {
    let fixed = '';
    let dec = '';
    let vir = false;
    const sNumber = invalue.toString();
    for (
      let i = 0, len = sNumber.length;
      i < len && (dec.length < indec || indec === 0);
      i += 1
    ) {
      if (!vir) {
        if (sNumber.charAt(i) === '.') {
          vir = true;
        } else {
          fixed = fixed + sNumber.charAt(i);
        }
      } else if (indec > dec.length) {
        dec = dec + sNumber.charAt(i);
      }
    }
    let output = fixed + dec;
    for (let i = 0; dec.length < indec; i += 1) {
      output = output + '0';
      dec = dec + '0';
    }
    return output;
  }

  function toHex(str: string) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }
  //10000000000000000
  //9223372036854775807

  //10000 && 18 decimales
  function toHexDec(d: number) {
    let result = '';
    result = Number(d).toString(16);
    if (Math.abs(result.length % 2) == 1) {
      result = '0' + result;
    }
    return result;
    //return  ((Number(d).toString(16)));//.slice(-2).toUpperCase();
  }
  function bigToHexDec(d: bigint) {
    let result = '';
    result = d.toString(16);
    if (Math.abs(result.length % 2) == 1) {
      result = '0' + result;
    }
    return result;
    //return  ((Number(d).toString(16)));//.slice(-2).toUpperCase();
  }

  function copyData() {
    let data = '';
    data =
      'issue' +
      '@' +
      toHex(tokenName) +
      '@' +
      toHex(tokenTicker) +
      '@' +
      bigToHexDec(bigAmount) +
      '@' +
      toHexDec(tokenDecimals);

    if (canFreeze) {
      data =
        data + '@' + toHex('canFreeze') + '@' + toHex(canFreeze.toString());
    }
    if (canWipe) {
      data = data + '@' + toHex('canWipe') + '@' + toHex(canWipe.toString());
    }
    if (canPause) {
      data = data + '@' + toHex('canPause') + '@' + toHex(canPause.toString());
    }
    if (canMint) {
      data = data + '@' + toHex('canMint') + '@' + toHex(canMint.toString());
    }
    if (canBurn) {
      data = data + '@' + toHex('canBurn') + '@' + toHex(canBurn.toString());
    }
    if (canChangeOwner) {
      data =
        data +
        '@' +
        toHex('canChangeOwner') +
        '@' +
        toHex(canChangeOwner.toString());
    }
    {
      data =
        data + '@' + toHex('canUpgrade') + '@' + toHex(canUpgrade.toString());
    }
    if (canAddSpecialRoles) {
      data =
        data +
        '@' +
        toHex('canAddSpecialRoles') +
        '@' +
        toHex(canAddSpecialRoles.toString());
    }
    copy(data);
  }
  function copyAddress() {
    copy('erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u');
  }

  function copyGas() {
    copy('60000000');
  }
  function copyAmount() {
    copy('0.05');
  }
  /*
        canUpgradetrue
        63616e55706772616465
        74727565

        canUpgradefalse
        63616e55706772616465
        66616c7365
*/

  /**/
  return (
    <div className='container py-4'>
      <p>
        ESDT generator :<br />
        #0 -{' '}
        <a
          target='_BLANK'
          href='https://docs.multiversx.com/tokens/esdt-tokens'
          rel='noreferrer'
        >
          Read the documentation
        </a>{' '}
        <br />
        #1 -{' '}
        <a
          target='_BLANK'
          href='https://testnet-wallet.multiversx.com/'
          rel='noreferrer'
        >
          Create a wallet
        </a>{' '}
        <br />
        #2 -{' '}
        <Link className='' to={'/faucet'}>
          Claim some xEGLD
        </Link>{' '}
        <br />
        #3 - Use the wallet to send a transaction with helper values <br />
      </p>
      <Form>
        <Row className='mb-3'>
          <Form.Group as={Col} md='6' controlId='tokenName'>
            <Form.Label>
              Name 3-20 [a-zA-Z0-9] {tokenName && '@' + toHex(tokenName)}
            </Form.Label>
            <Form.Control
              required
              type='text'
              placeholder=''
              value={tokenName}
              onChange={handleTokenNameChange}
            />
          </Form.Group>

          <Form.Group as={Col} md='6' controlId='tokenTicker'>
            <Form.Label>
              Ticker 3-10 [A-Z0-9] {tokenTicker && '@' + toHex(tokenTicker)}
            </Form.Label>
            <Form.Control
              required
              type='text'
              placeholder=''
              value={tokenTicker}
              onChange={handleTokenTickerChange}
            />
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group
            as={Col}
            md='6'
            controlId='TokenAmount'
            onChange={handleTokenAmountChange}
          >
            <Form.Label>
              Amount {'@' + bigToHexDec(bigAmount)} ({bigAmount.toString()})
            </Form.Label>
            <Form.Control
              required
              type='number'
              placeholder=''
              defaultValue='0'
              value={tokenAmount}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            md='6'
            controlId='TokenDecimals'
            onChange={handleTokenDecimalsChange}
          >
            <Form.Label>
              Decimals (18 max) {'@' + toHexDec(tokenDecimals)}{' '}
              {tokenDecimals > 0 &&
                '(0.' +
                  Math.pow(10, tokenDecimals)
                    .toString()
                    .substring(1, tokenDecimals + 1) +
                  ')'}
            </Form.Label>
            <Form.Control
              required
              type='number'
              placeholder=''
              defaultValue='0'
              value={tokenDecimals}
            />
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group
            as={Col}
            md='2'
            controlId='canFreeze'
            onChange={handleCanFreeze}
          >
            <Form.Check
              inline
              label='canFreeze'
              type='checkbox'
              checked={canFreeze}
              id='inline-checkbox-1'
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canWipe'
            onChange={handleCanWipe}
          >
            <Form.Check
              inline
              label='canWipe'
              type='checkbox'
              checked={canWipe}
              id='inline-checkbox-2'
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canPause'
            onChange={handleCanPause}
          >
            <Form.Check
              inline
              label='canPause'
              type='checkbox'
              checked={canPause}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canMint'
            onChange={handleCanMint}
          >
            <Form.Check
              inline
              label='canMint'
              type='checkbox'
              checked={canMint}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canBurn'
            onChange={handleCanBurn}
          >
            <Form.Check
              inline
              label='canBurn'
              type='checkbox'
              checked={canBurn}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canChangeOwner'
            onChange={handleCanChangeOwner}
          >
            <Form.Check
              inline
              label='canChangeOwner'
              type='checkbox'
              checked={canChangeOwner}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canUpgrade'
            onChange={handleCanUpgrade}
          >
            <Form.Check
              inline
              label='canUpgrade'
              type='checkbox'
              checked={canUpgrade}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md='2'
            controlId='canAddSpecialRoles'
            onChange={handleCanAddSpecialRoles}
          >
            <Form.Check
              inline
              label='canAddSpecialRoles'
              type='checkbox'
              checked={canAddSpecialRoles}
            />
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Button variant='primary' onClick={handleSubmit}>
              Verify
            </Button>
          </Form.Group>
        </Row>
        {!address && ready && (
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Button variant='primary'>login to submit tx</Button>
            </Form.Group>
          </Row>
        )}
        {address && ready && (
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Actions datas={datas} />
            </Form.Group>
          </Row>
        )}
      </Form>

      {formControl && (
        <div className='alert alert-warning' role='alert'>
          {formControl}
        </div>
      )}

      <p>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={copyAddress}
        >
          <i className='fa fa-copy'></i>
        </button>
        send to : erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u
      </p>
      <p>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={copyAmount}
        >
          <i className='fa fa-copy'></i>
        </button>
        set amount to : 0.05
      </p>

      <p>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={copyGas}
        >
          <i className='fa fa-copy'></i>
        </button>
        set Gas to : 60000000
      </p>

      <p>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={copyData}
        >
          <i className='fa fa-copy'></i>
        </button>
        data :{' '}
        {'issue' +
          '@' +
          toHex(tokenName) +
          '@' +
          toHex(tokenTicker) +
          '@' +
          bigToHexDec(bigAmount) +
          '@' +
          toHexDec(tokenDecimals)}
        {canFreeze &&
          '@' + toHex('canFreeze') + '@' + toHex(canFreeze.toString())}
        {canWipe && '@' + toHex('canWipe') + '@' + toHex(canWipe.toString())}
        {canPause && '@' + toHex('canPause') + '@' + toHex(canPause.toString())}
        {canMint && '@' + toHex('canMint') + '@' + toHex(canMint.toString())}
        {canBurn && '@' + toHex('canBurn') + '@' + toHex(canBurn.toString())}
        {canChangeOwner &&
          '@' +
            toHex('canChangeOwner') +
            '@' +
            toHex(canChangeOwner.toString())}
        {'@' + toHex('canUpgrade') + '@' + toHex(canUpgrade.toString())}
        {canAddSpecialRoles &&
          '@' +
            toHex('canAddSpecialRoles') +
            '@' +
            toHex(canAddSpecialRoles.toString())}
      </p>
    </div>
    //    ESDTTransfer@'.htmlspecialchars($identifier).'@'.dechex($amount2).'
    //issue@'.ascii2hex($coinname).'@'.ascii2hex($ticker).'@'.$fixedAmount.'@'.$fixedDecimal.$options.'
  );
};

export default Esdt;
