import * as React from 'react';
import { dAppName } from 'config';

const Team = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 col-lg-5 mx-auto'>
          <div className='card shadow-sm rounded p-4 border-0'>
            <div className='card-body text-center'>
              <h2 className='mb-3' data-testid='title'>
                {dAppName}
              </h2>

              <p className='mb-3'>
                Made with love by :
                <ul className='list-group'>
                  <li className='list-group-item'>
                    <a
                      href='https://twitter.com/KevinLallement'
                      target='_BLANK'
                      rel='noreferrer'
                    >
                      @KevinLallement
                    </a>
                  </li>
                  <li className='list-group-item'>
                    <a
                      href='https://twitter.com/TheoSabattie'
                      target='_BLANK'
                      rel='noreferrer'
                    >
                      @TheoSabattie
                    </a>
                  </li>
                </ul>
                Telegram channels :
                <ul className='list-group'>
                  <li className='list-group-item'>
                    <a
                      href='https://t.me/R3D4_FR'
                      target='_BLANK'
                      rel='noreferrer'
                    >
                      R3D4.FR (FR/EN)
                    </a>
                    <br />
                  </li>
                  <li className='list-group-item'>
                    <a
                      href='https://t.me/MultiversXDevt_fr'
                      target='_BLANK'
                      rel='noreferrer'
                    >
                      MultiversX developers (FR)
                    </a>
                    <br />
                  </li>
                  <li className='list-group-item'>
                    <a
                      href='https://t.me/MultiversXDevelopers'
                      target='_BLANK'
                      rel='noreferrer'
                    >
                      MultiversX developers (EN)
                    </a>
                    <br />
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
